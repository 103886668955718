<template>
  <div
    id="app"
  >
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return {
    }
  },
  computed:{
  },
  beforeDestroy () {
  },
  methods:{
    lockOrientation () {
      if (window.orientation !== 0) {
        window.orientation === -90 || window.orientation === 90 ? screen.orientation.lock('landscape') : screen.orientation.lock('portrait')
      }
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
}
</style>
