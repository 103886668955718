<template>
  <div class="content">

    <div class="upload-card">
      <div
        v-if="imgUrl"
        class="img-content">
        <i
          class="el-icon-close close"
          @click="handleDel"
        ></i>
        <div class="img">
          <img
            :src="imgUrl"
            alt=""/>
        </div>
      </div>
      <div
        v-else
        class="add-icon">
        <div class="face">
          <input
            type="file"
            name="file"
            class="upload"
            accept="image/*"
            capture="camera"
            @change="uploadImg">
          <v-button>相機拍攝 Take a photo</v-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {uploadFile} from '@api/common'

export default {
  props:{
    value:{
      type:[Object,String],
      default:()=>({})
    },
    otherData:{
      type:Object,
      default:()=>({})
    }
  },
  data() {
    return {
      imgUrl: ''
    }
  },
  watch:{
    value:{
      immediate:true,
      handler(val){
        if(val){
          let {url=''} = val||{}
          this.imgUrl = url?url:this.imgUrl
        }
      }
    }
  },
  created() {
  },
  methods: {
    uploadImg(e) {
      let file = e.target.files[0]
      let url = ''
      let that = this
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        url = this.result.substring(this.result.indexOf(',') + 1)
        that.imgUrl = 'data:image/png;base64,' + url
      }
      console.log('e---',e,file)
      let params = new FormData()
      params.append('file', file)
      this.network().uploadOrderFile(params)
    },
    handleDel(){
      this.imgUrl = ''
      this.$emit('input',{})
      this.$emit('del',{
        otherData:this.otherData
      })
    },
    network() {
      return {
        uploadOrderFile: async (params, result) => {
          const { data } = await uploadFile(params)
          this.$emit('input',data)
          this.$emit('uploadSuccess', {
            data:data,
            otherData:this.otherData
          })
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.content {
  height: 100%;
  overflow: hidden;
}
.face {
  position: relative;
  .upload {
    height: 56px;
    line-height: 56px;
    opacity: 0;
    position: absolute;
    z-index: 22;
    left: 0;
    margin: auto;
    right: 0;
  }
}
.upload-card {
  z-index: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 348px;
  height: 200px;
  background: #F8F8FB;
  border: 1px solid #1A1A1C;
  border-radius: 8px;
  .img-content{
    padding: 0 32px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .img{
      height: 200px;
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
    }
    img {
      max-width: 100%;
      max-height: calc( 100% - 2px);
      z-index: 3;
    }
    .close{
      position: absolute;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      top: 10px;
      right: 10px;
      z-index: 9;
      color: #000000;
    }
  }
  .add-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .el-button{
      width: 200px;
    }
  }
}
</style>
