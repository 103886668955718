export function filterChinese(val) {
  // 将中文去掉
  let str = val.replace(/[\u4E00-\u9FA5]/g, '')
  let tmp = ''
  // 将中文标点符号替换英文符号
  for (var i = 0; i < str.length; i++) {
    if (str[i].charCodeAt(0) > 65280 && str[i].charCodeAt(0) < 65375) { // 如果位于全角！到全角～区间内
      tmp += String.fromCharCode(str.charCodeAt(i) - 65248)
    } else if (str.charCodeAt(i) == 8216) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 8216 + 39)
    } else if (str.charCodeAt(i) == 8217) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 8217 + 39)
    } else if (str.charCodeAt(i) == 8220) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 8220 + 34)
    } else if (str.charCodeAt(i) == 8221) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 8221 + 34)
    } else if (str.charCodeAt(i) == 12290) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 12290 + 46)
    } else if (str.charCodeAt(i) == 12288) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 12288 + 32)
    } else {
      tmp += str[i]
    }
  }
  return tmp
}
