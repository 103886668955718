<template>
  <section class="flex">
    <v-input
      id="input1"
      ref="input1"
      v-model="hkid1"
      :disabled="disabled"
      size="small"
      isUpper
      maxlength="2"
      keyNonChinese
      :validate-event="isvalidate"
      @blur="handleBlur"
      @focus.native="handleFocus(1)"
      @keyup.native="handleKeyUp"
      @keydown.native="handleKeyDown($event, 'hkid1')"
      @input="handleInput($event, 'hkid1', 2)"></v-input>
    <v-input
      id="input2"
      ref="input2"
      v-model="hkid2"
      type="tel"
      :key-number="true"
      :disabled="disabled"
      size="small"
      :validate-event="isvalidate"
      :maxlength="6"
      @blur="handleBlur"
      @focus="handleFocus(2)"
      @keyup.native="handleKeyUp"
      @keydown.native="handleKeyDown($event, 'hkid2')"
      @input="handleInput($event, 'hkid2', 6)"
    ></v-input>
    <section class="flex-input flex">
      <span class="icon-dot">(</span>
      <v-input
        id="input3"
        ref="input3"
        v-model="hkid3"
        type="text"
        class="year"
        :disabled="disabled"
        :validate-event="isvalidate"
        size="small"
        :maxlength="1"
        @blur="handleBlur"
        @focus="handleFocus(3)"
        @keyup.native="handleKeyUp"
        @keydown.native="handleKeyDown($event, 'hkid3')"
        @input="handleInput($event, 'hkid3', 1)"></v-input>
      <span class="icon-dot">)</span>
    </section>
  </section>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'VHkidInput',
  model:{
    value: {
      type: [String, Number],
      default: ''
    },
  },
  props:{
    value:{
      type: String,
      default: ''
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hkid3: '',
      hkid2: '',
      hkid1: '',
      focusIndex: 1,
      iskeydown: false
    }
  },
  computed:{
    isvalidate(){
      return !!(this.hkid1&&this.hkid2&&this.hkid3)
    }
  },
  watch:{
    value:{
      immediate: true,
      handler(val){
        if(val){
          // // 先把日期后面的时间给去掉

          let str_en = val.slice(0,2)
          let is_all_en  = /^[a-zA-Z]+$/.test(str_en)
          let str = is_all_en?val.slice(2,val.length):val.slice(1,val.length)
          this.hkid1 = is_all_en?str_en:val.slice(0,1)
          this.hkid2 = str.slice(0,str.length-1)
          this.hkid3 = str.slice(str.length-1,str.length)
        }
      }
    },
  },
  methods: {
    handleKeyUp(){
      this.iskeydown = false
    },

    handleKeyDown(e, key){
      this.iskeydown = true
    },

    handleInput(val, key, len){
      // if(this.iskeydown){
      //   if(this[key].length > len-1){
      //     // 如果输入的内容长度超过限定的长度则跳到下一个输入框并将最后那个输入的数字赋值到下一个输入框
      //     let res = val.toString()
      //     let nextFocusIndex = this.focusIndex + 1
      //     this[key] = res.slice(0, len)
      //     this.focusIndex = nextFocusIndex >= 3? 3 : nextFocusIndex
      //     document.getElementById(`input${this.focusIndex}`).children[0].focus()
      //   }
      // }

      let day = `${this.hkid1}${this.hkid2}${this.hkid3}`
      if(this.hkid1 && this.hkid2 && this.hkid3){
        this.$emit('input', day)
      }else{
        this.$emit('input', '')
      }
    },

    handleFocus(index){
      this.focusIndex = index
    },

    handleBlur(){
      this.$emit('change')
    },
  },
}
</script>
<style lang='scss' scoped>
.el-input{
  display: inline-block;
  //width: 80px;
  margin-right: 15px;
  &:first-child{
    width:70px;
    /deep/.el-input__inner{
      width: 70px;
    }
  }
}
.flex-input{
  align-items: center;
  .el-input {
    display: inline-block;
    //width: 80px;
    width: 50px;
    margin-right: 8px;
    margin-left: 8px;
    /deep/ .el-input__inner {
      width: 50px;
    }
  }
  .icon-dot{
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    font-feature-settings: 'pwid' on;

    color: #1A1A1C;
  }
}
.year{
  width: 100px;
}
.flex{
  .el-input:last-child{
    margin-right: 0;
  }
}
.el-input {
  width: 100%;
  height: 50px;
  & /deep/ .el-input-group__prepend {
    padding: 0 10px;
  }
  &.el-input--prefix{
    /deep/ .el-input__inner {
      padding-left: 62px !important;
      font-size: 16px;
      color: #1A1A1C;
    }
  }
  & /deep/ .el-input__prefix {
    left: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1A1A1C;
    font-weight: 400;
    font-size: 16px;
    line-height: 175%;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    font-feature-settings: 'pwid' on;
  }
  &.no-border /deep/ .el-input__inner {
    border: 0!important;
    font-size: 16px;
    color: #1A1A1C;
  }
  /deep/.el-input__inner{
    height: 50px;
    line-height: 50px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    color: #1A1A1C;
    &:focus{
      border:2px solid #4979F5!important;
    }
    border:1px solid #1A1A1C;
    //&:active{
    //  border:2px solid #4979F5!important;
    //}
  }
  &.is-disabled{
    /deep/.el-input__inner{
      background: #F1F1F4;
      border: 1px solid #949497;
      color: #949497;
    }
  }
}
</style>
