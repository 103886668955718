import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

import common from './common'
import quotation from './quotation'
export default new Vuex.Store({
  modules: {
    common,
    quotation
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})
