<template>
  <div
    class="v-radio-group"
    :class="{'v-radio-group__button':button,
             'v-radio-group__flex':flex,
             'v-radio-group__flex-height':isSplit
    }">
    <el-radio-group
      v-model="radio"
      :size="size"
      :disabled="disabled"
      @change="changeRadio">
      <template v-if="!button">
        <el-radio
          v-for="(item, index) in list"
          :key="index"
          :border="border"
          :disabled="disabledList.includes(item[valueKey])"
          :class="{ 'block': block }"
          :label="item[valueKey]">
          <template v-if="!isSplit">
            <p>{{ item[labelKey] }}</p>
            <p>{{ item[labelKeyZh] }}</p>
          </template>
          <template v-else>
            <div>
              <p>{{ labelName(item[labelKey]) }}</p>
              <p>{{ labelName(item[labelKey],true) }}</p>
            </div>
          </template>
        </el-radio>
      </template>
      <template v-else>
        <el-radio-button
          v-for="(item, index) in list"
          :key="index"
          :disabled="disabledList.includes(item[valueKey])"
          :class="{ 'block': block,'theme':button }"
          :label="item[valueKey]">{{ item[labelKey] }}</el-radio-button>
      </template>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: 'VRadioGroup',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    labelKeyZh: {
      type: String,
      default: 'name_zh'
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isSplit: {
      type: Boolean,
      default: false
    },
    disabledList: {
      type: Array,
      default: () => ([])
    },
    button: {
      type: Boolean,
      default: false
    },
    flex: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'mini'
    },
    block: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      radio: ''
    }
  },
  computed: {
    isNumber() {
      const id = this.list[0][this.valueKey]
      return typeof id === 'number'? true: false
    },
    labelName(){
      return(item,isEn)=>{
        let name_zh = item.split(' ')[0]
        let name_en = item.split(name_zh)[1]
        return isEn?name_en:name_zh
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if(val === null || val === '') {
          this.radio = ''
        }
        else if(val !== this.radio) {
          this.radio = this.isNumber? Number(val): String(val)
        }
      }
    },
    radio: {
      handler(val) {
        if(val !== this.value) {
          this.$emit('input', val)
          this.$emit('change', val)
        }
      }
    }
  },
  methods: {
    // 這樣寫不知道爲什麽必須改變兩次才會觸發 element 的表單校驗
    changeRadio(val) {
      // this.$emit('input', val)
      // this.$emit('change', val)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-radio-group {
  .block {
    padding-bottom: 5px;
    width: 100%;
  }
  &__button{
    .el-radio-group{
      display: flex;

    }
  }
  &__flex{
    &-height{
      height: 80px;
      .el-radio{
        &.is-bordered{
          height: 80px!important;
          margin-bottom: 0!important;
        }
      }
    }
    .el-radio-group{
      display: flex;
      .el-radio{
        width: 50%;
        margin-right:8px;
        display: flex;
        align-items: center;
        .el-radio__label{
          padding-left: 12px;
        }
        p{
          font-weight: 400;
          font-size: 16px;
          line-height: 175%;
          display: flex;
          align-items: center;
          letter-spacing: 0.02em;
          color: #1A1A1C;
        }
        &:last-child{
          margin-right: 0;
        }
        &.is-bordered{
          padding-top: 12px;
          padding-bottom: 12px;
          margin-bottom: 0!important;
          /deep/.el-radio__label{
            display: block!important;
          }
        }

      }
    }
  }
  ::v-deep .theme{
    background: #fff;
    color: #076081;
    border-radius: 4px;
    .el-radio-button__inner{
      background: #fff;
      color: $primary;
      border-right:1px solid #CBD5E0;
      border-right: 1px solid #CBD5E0;
      height: 50px;
      font-weight: 400;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: none!important;
    }
  }
  ::v-deep .is-active{
    .el-radio-button__inner{
      background: $primary!important;
      color: #fff;
      border-right: 0.5px solid rgba(255, 255, 255, 0.2);;
    }
  }
  ::v-deep .el-radio-group{
    //.el-radio__input.is-checked .el-radio__inner{
    //  background:  $primary!important;
    //  border: 2px solid $primary;
    //}
    //.el-radio__input.is-checked+.el-radio__label{
    //  color: $primary;
    //}
    width: 100%;

    .el-radio{
      .el-radio__inner{
        border: 1px solid #1A1A1C;
        height: 24px;
        width: 24px;
        //margin-right: 16px;
      }
      &.is-checked{
        //border: 2px solid $primary!important;
        //height: 55px;
        //padding: 15px;
        .el-radio__inner{
          background: #fff!important;
          border-color: $primary!important;
          &::after{
            width: 12px;
            height: 12px;
            background-color: $primary;
          }
        }
      }
      &.is-disabled{
        background: #F1F1F4;
        border: 1px solid #949497!important;
        .el-radio__inner{
          border: 1px solid #949497!important;
          &::after{
            width: 12px;
            height: 12px;
            background-color: #949497;
          }
        }
        .el-radio__label{
          color: #949497;
        }
      }
      &.is-bordered{
        border: 1px solid #1A1A1C;
        border-radius: 8px;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 16px;
        margin-bottom: 8px;
        margin-left:0;
        &:last-child{
          margin-bottom: 0;
        }
        .el-radio__inner{
          border: 1px solid #1A1A1C;
          height: 24px;
          width: 24px;
          //margin-right: 16px;
        }
        .el-radio__label{
          font-weight: 400;
          font-size: 16px;
          line-height: 175%;
          display: flex;
          align-items: center;
          letter-spacing: 0.02em;
          color: #1A1A1C;
          padding-left: 12px;
        }
        &.is-checked{
          border: 2px solid $primary!important;
          //height: 55px;
          padding: 15px;
          .el-radio__inner{
            background: #fff!important;
            border-color: $primary!important;
           &::after{
             width: 12px;
             height: 12px;
             background-color: $primary;
           }
          }
        }
        &.is-disabled{
          background: #F1F1F4;
          border: 1px solid #949497!important;
          .el-radio__inner{
            border: 1px solid #949497!important;
            &::after{
              width: 12px;
              height: 12px;
              background-color: #949497;
            }
          }
          .el-radio__label{
            color: #949497;
          }
        }
      }
    }
  }
}

</style>
