import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCheck,
  faClosedCaptioning,
  faDownload,
  faEyeSlash,
  faEye
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const fa = [
  faCheck,
  faClosedCaptioning,
  faDownload,
  faEyeSlash,
  faEye
]

fa.some(icon => {
  library.add(icon)
})


Vue.component('font-awesome-icon', FontAwesomeIcon)
