<template>
  <!-- 控制事件冒泡-->
  <el-button
    v-if="stop"
    ref="ref-button"
    :size="size"
    :type="type"
    :disabled="disabled"
    :plain="plain"
    @click.stop="handlerClick">
    <font-awesome-icon
      v-if="icon"
      class="icon"
      :icon="[iconType, icon]" />
    <span class="btn-label">
      <slot></slot>
    </span>
  </el-button>
  <el-button
    v-else
    ref="ref-button"
    :size="size"
    :type="type"
    :disabled="disabled"
    :plain="plain"
    @click="handlerClick">
    <span class="flex">
      <font-awesome-icon
        v-if="icon"
        class="icon"
        :icon="[iconType, icon]" />
      <span class="btn-label">
        <slot></slot>
      </span>
    </span>
  </el-button>
</template>
<script>
export default {
  name: 'VButton',
  props: {
    size: {
      type: String,
      default: 'mini'
    },
    type: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    iconType: {
      type: String,
      default: 'fas'
    },
    stop: {
      // 控制事件是否冒泡上傳
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handlerClick() {
      this.$emit('click')
    },
    currentClick() {
      this.$refs['ref-button'].$el.click()
    }
  }
}
</script>
<style lang="scss" scoped>
.el-button {
  width: 169px;
  height: 50px;
  background: #003EE5;
  border-radius: 8px;
  .icon{
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 150%;
    margin-right: 6px;
    color: #003EE5;
  }
  &:active{
    background: #0030B2;
  }
  //&:hover{
  //  background: #0030B2;
  //}
  &.is-disabled{
    background: #949497;
    &:active{
      background: transparent;
    }
  }
  .btn-label{
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    flex: 1;
  }
  &.is-plain{
    color: $primary;
    border: 1px solid $primary;
    background: white;
    .btn-label{
      color: $primary;
    }
    .flex{
      align-items: center;
      .icon{

      }
      .btn-label{
        flex: 1;
      }
      @media screen and (max-width: 768px) {
        .icon{
          display: none;
        }
      }
    }
    &:active{
      background: #E8F1FE;
    }
    &.is-disabled{
      color: #949497!important;
      border: 1px solid #949497;
      .icon{
        color: #949497!important;
      }
      .btn-label{
        color: #949497!important;
      }
      &:active{
        background: transparent;
      }
    }
  }
}
</style>
