<template>
  <div class="v-dialog">
    <el-dialog
      :close-on-click-modal="closeOnclickModal"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="dialogVisible"
      custom-class="width-dialog-sm-and-down"
      :top="top"
      :width="width">
      <slot></slot>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'VDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '500px'
    },
    top: {
      type: String,
      default: '15vh'
    },
    closeOnclickModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: this.value
    }
  },
  watch: {
    value(val) {
      if(val !== this.dialogVisible) {
        this.dialogVisible = val
      }
    },
    dialogVisible(status) {
      this.$emit('input', status)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-dialog {
  & /deep/ .el-dialog__header {
    padding: 0;
  }
  & /deep/ .el-dialog__body {
      padding: 0;
  }
}
</style>
