import Vue from 'vue'
import dayjs from 'dayjs'
// 全局日期，涉及到日期的方法都用這個換算
Vue.prototype.$dayjs = (date) => {
  if(date) {
    return dayjs.tz(date)
  }
  else {
    return dayjs.tz()
  }
}
// 僅用於存放全局數據/方法
Vue.mixin({
  filters: {

  },
  data() {
    return {

    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    }
  },
  watch: {

  },
  methods: {
    changeLanguage() {
      const lang = localStorage.getItem('lang') === 'en-US' ? 'zh-HK' : 'en-US'
      localStorage.setItem('lang', lang)
      this.$i18n.locale = lang
      this.$vantLocale(lang)
    }
  }
})
