<template>
  <section class="section-input-container select-container">
    <van-field
      ref="DateBirth"
      v-model="DateBirthline"
      :name="name"
      class="v-DateBirth-group"
      :border="false"
      :rules="rules"
    >
      <template #input>
        <div class="DateBirthBox">
          <div class="flex-center db-box">
            <div class="db-sm-input mg-r-10">
              <!-- :rules="[{ required: true }]" -->
              <van-field
                ref="input1"
                v-model="DateBirth.DD"
                :name="name"
                type="number"
                placeholder="DD"
                class="v-input"
                :maxlength="3"
                :disabled="disabled"
                :keyNumber="true"
                @input="ChangeInput('DD',2,$event)"
                @focus="onFocus(1)"
                @keyup="onKeyUp"
                @keydown="onKeyDown('DD',$event)"
                @blur="handlerBlur"
              ></van-field>
            </div>
            <div class="db-sm-input mg-r-10">
              <van-field
                ref="input2"
                v-model="DateBirth.MM"
                :name="name"
                type="number"
                placeholder="MM"
                class="v-input"
                :maxlength="3"
                :disabled="disabled"
                :keyNumber="true"
                @input="ChangeInput('MM',2,$event)"
                @focus="onFocus(2)"
                @keyup="onKeyUp"
                @keydown="onKeyDown('MM',$event)"
                @blur="handlerBlur"
              ></van-field>
            </div>
            <div class="db-lar-input">
              <van-field
                ref="input3"
                v-model="DateBirth.YYYY"
                placeholder="YYYY"
                class="v-input"
                :name="name"
                type="number"
                :maxlength="4"
                :disabled="disabled"
                :keyNumber="true"
                @input="ChangeInput('YYYY',4,$event)"
                @focus="onFocus(3)"
                @keyup="onKeyUp"
                @keydown="onKeyDown('YYYY',$event)"
                @blur="handlerBlur"
              ></van-field>
            </div>
          </div>
          <!-- <span
            v-if="errTips"
            class="van-field__error-message">
            {{ errTips }}
          </span> -->
        </div>
      </template>
    </van-field>
  </section>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: '',
  components: {},
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errTips: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      DateBirthline: '',
      DateBirth: {
        DD: '',
        MM: '',
        YYYY: '',
      },
      FocusIndex : 0,
      iskeydown : false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(date) {
        // console.log(date)
        if(date != this.DateBirthline){
          if(date) {
            // 数据填充处理
            const day = dayjs(date)
            const y = day.$y
            const m = String((day.$M + 1)).padStart(2, '0')
            const d = String(day.$D).padStart(2, '0')
            this.DateBirth.DD = d
            this.DateBirth.MM = m
            this.DateBirth.YYYY = y
            this.DateBirthline = `${y}-${m}-${d}`
          }
          else {
            this.DateBirth.DD = ''
            this.DateBirth.MM = ''
            this.DateBirth.YYYY = ''
            this.DateBirthline = ''
          }
        }
      }
    }
  },
  mounted() {
  },
  methods: {
    onKeyUp(){
      this.iskeydown = false
    },
    onKeyDown(key,e){
      this.iskeydown = true
      const prevIndex = this.FocusIndex - 1
      let val = this.DateBirth[key] + ''
      if(e.keyCode == 8){
        // console.log(val)
        e.preventDefault()
        if(val){
          this.DateBirth[key] = val.substring(0,val.length - 1)
        }else{
          this.FocusIndex = prevIndex < 1 ? 1 : prevIndex
          this.$refs[`input${this.FocusIndex}`].focus()
        }
      }
    },
    ChangeInput(key,len,$event){
      let str = $event + ''
      if(this.iskeydown){
        // console.log(str)
        // 只有键盘输入，才启动自动focus
        if(str.length > len){
          this.DateBirth[key] = str.slice(0,len)
          let newNextVal = str.slice(len)
          let index = this.FocusIndex + 1
          this.FocusIndex = index > 3 ? 3 : index
          let oldNextVal = this.$refs[`input${this.FocusIndex}`].value

          this.$refs[`input${this.FocusIndex}`].focus()

          this.$nextTick(() => {
            if(!oldNextVal){
              // 没有旧数据
              let nextKey = Object.keys(this.DateBirth)[this.FocusIndex - 1]
              this.DateBirth[nextKey] = newNextVal
            }
          })

        }
      }else{
        return
      }
    },
    onFocus(index){
      this.FocusIndex = index
    },
    handlerBlur() {
      const { DD, MM, YYYY } = this.DateBirth
      let nDate = DD.trim()
      let nMonth = MM.trim()
      let nYear = YYYY.trim()
      const curYear = dayjs().year()
      const minYear = 1900
      if(nDate){
        nDate = nDate <= 0 ? 1 : nDate
        nDate = nDate > 31 ? 31 : String(nDate).padStart(2, '0')
        this.DateBirth.DD = nDate
      }

      if(nMonth){
        nMonth = nMonth <= 0 ? 1 : nMonth
        nMonth = nMonth > 12 ? 12 : String(nMonth).padStart(2, '0')
        this.DateBirth.MM = nMonth
      }


      if(nYear){
        if(nYear > curYear){
          nYear = curYear
        }else if (nYear < minYear){
          nYear = minYear
        }
        this.DateBirth.YYYY = nYear
      }


      if(nDate && nMonth && nYear) {
        // 获取当前月份的天数
        let daysInMonth = dayjs(`${nYear}-${nMonth}-1`).daysInMonth()
        nDate = nDate > daysInMonth ? daysInMonth : nDate  // 输入的超出当前月份的天数
        this.DateBirth.DD = nDate
        const date = dayjs(`${nYear}-${nMonth}-${nDate}`)
          .format('YYYY-MM-DD')
        this.DateBirthline = date
        // this.$emit('input', date)
        // 失去焦点验证，该节点没有input，所以Trigger事件无效，需手动验证
        this.$refs.DateBirth.validate(this.rules)
      }
      else{
        this.DateBirthline = ''
      }

      this.$emit('input', this.DateBirthline)

    }
  }
}
</script>

<style scoped lang="scss">
.v-DateBirth-group {
  overflow: unset;
  &.van-field--error{
    /deep/ .v-input{
      .van-field__control{
        box-shadow: 0 0 0 0.1875rem #ffd5d5;
        border: 0.0625rem solid #E42424;
        color: #012a63;
      }
    }
  }
}
.DateBirthBox,.db-box{
  width: 100%;
}
.db-box {
  flex-wrap: wrap;
}
.db-lar-input {
  // width: 195px;
  flex: 1;
}
.db-sm-input {
  width: 60px;
}
.mg-r-10{
  margin-right: 10px;
}
</style>
