import axios from '@/jslib/axios'

export const getGlobalOptions = params => {
  return axios({
    method: 'get',
    url: '/api/options',
    params
  })
}
export const uploadFile = data => {
  return axios({
    method: 'post',
    url: '/api/upload',
    data
  })
}
export const uploadSignature = data => {
  return axios({
    method: 'post',
    url: '/api/upload/signature',
    data
  })
}
