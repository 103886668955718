<template>
  <v-dialog
    v-model="show"
    top="40vh"
    width="335px">
    <div class="dialog-container">
      <span
        class="cancle-icon hand"
        @click="onCancel">
        <i class="el-icon-close"></i>
      </span>
      <h3 v-if="title">{{ title }}</h3>
      <p>
        {{ message_en }}
      </p>
      <p>
        {{ message }}
      </p>
      <div class="dialog-footer">
        <div
          v-if="isCancel"
          class="half">
          <v-button
            plain
            @click="onCancel">{{ cancelBtn }}</v-button>
        </div>
        <div
          class="half"
          :class="{'w-fill':!isCancel}">
          <v-button
            class="button-theme"
            :class="classButton?'classButton':''"
            @click="onConfirm">{{ confirmBtn }}</v-button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmBox',
  data() {
    return {
      show: false,
      title: '',
      message: '',
      message_en: '',
      cancelBtn: 'Cancel',
      confirmBtn: 'Confirm',
      promiseStatus: null,
      isCancel:true,
      classButton:false
    }
  },
  methods: {
    confirm() {
      return new Promise((resolve, reject) => {
        this.promiseStatus = {
          resolve,
          reject
        }
      })
    },
    onCancel() {
      this.show = false
      this.promiseStatus.reject()
    },
    onConfirm() {
      this.show = false
      this.promiseStatus.resolve()
    }
  }
}
</script>
<style lang="scss" scoped>

.dialog-container {
      padding: 30px;
      color: black;
      word-wrap: break-word;
      position: relative;
      .cancle-icon{
        position: absolute;
        top: -10px;
        right: -10px;
        width: 36px;
        height: 36px;
        padding: 6px;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        background: #FFF;
        box-shadow: 0px 4px 16px 0px rgba(177, 175, 175, 0.20);
        i{
          color: #000;
          text-align: center;
          font-family: Material Symbols Rounded;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      h3 {
          padding-bottom: 20px;
          font-size: 18px;
          line-height: 27px;
          font-weight: 700;
      }
      p {
        color:#1A1A1C;
        font-feature-settings: 'pwid' on;
        /* Field Label */
        font-family: Noto Sans HK;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
        letter-spacing: 0.28px;
        text-align: center;
        word-break: break-word;
      }
      .dialog-footer {
          display: flex;
          justify-content: space-between;
          //justify-content: flex-end;
          margin-top: 15px;
        .half{
          width: calc(50% - 8px);
          .el-button {
            width: 100%;
          }
        }
        .w-fill{
          width: 100%;
        }

      }
  .classButton{
    background:rgb(211,76,70)!important;
    color: #fff!important;
  }
    }
</style>
