
const SET_QUOTE_INFORMATION_FORM = 'SET_QUOTE_INFORMATION_FORM'
const SET_QUOTATION_DATA = 'SET_QUOTATION_DATA'

const state = {
  quoteInformationForm: null,
  quotationData:null,
}

const mutations = {
  [SET_QUOTATION_DATA]: (state, value) => {
    state.quotationData= value
  },

  [SET_QUOTE_INFORMATION_FORM]: (state, value) => {
    state.quoteInformationForm= value
  },
  // [SET_PRODUCT_ID]: (state, value) => {
  //   state.productID= value
  // },
}

const limit = {
  time: '',
  value: ''
}

const actions = {
  setQuotationData({ commit }, val) {
    commit(SET_QUOTATION_DATA, val)
  },
  setQuoteInformationForm({ commit }, val) {
    commit(SET_QUOTE_INFORMATION_FORM, val)
  },
  setNcd({ commit }, val) {
    commit(SET_NCD, val)
  },
  setNcdList({ commit }, val) {
    commit(SET_NCD_LIST, val)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
