export default [
  {
    path: '/RacingMax/verify/login',
    name: 'verify',
    component: () => import('@/views/verify/login'),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: '/RacingMax/doc/download',
    name: 'download',
    component: () => import('@/views/verify/download'),
    meta: {
      keepAlive: false,
    },
  },
]
