import Vue from 'vue'

import MessageBox from '@/components/message_box/main'
import ConfirmBox from '@/components/confirm_box/main'
import LazyDom from '@/components/lazy-dom'
Vue.prototype.$MessageBox = MessageBox.install
Vue.prototype.$ConfirmBox = ConfirmBox.install

import VSelect from '@/components/controls/v-select'
import VInput from '@/components/controls/v-input'
import VButton from '@/components/controls/v-button'
import VDatePicker from '@/components/controls/v-date-picker'
import VMonthPicker from '@/components/controls/v-month-picker'
import VRadioGroup from '@/components/controls/v-radio-group'
import VCheckGroup from '@/components/controls/v-checkbox-group'
import VCheck from '@/components/controls/v-checkbox'
import VRowCol from '@/components/controls/v-row-col'
import VTitle from '@/components/controls/v-title'
import VUpload from '@/components/controls/v-upload'
import VDialog from '@/components/controls/v-dialog'
import FileContainer from '@/components/controls/file-container'
import VPagination from '@/components/controls/v-pagination'
import VHeader from '@/components/controls/v-header'
import VInputSelect from '@/components/controls/v-input-select'
import VPageContainer from '@/components/v-page-container'
import VTakePhoto from '@/components/controls/v-take-photo'
import VBirthDay from '@/components/controls/v-birth-day'
import VHkidInput from '@/components/controls/v-hkid-input'
Vue.component('lazy-dom', LazyDom)
Vue.component('v-date-picker', VDatePicker)
Vue.component('v-month-picker', VMonthPicker)
Vue.component('v-select', VSelect)
Vue.component('v-input', VInput)
Vue.component('v-button', VButton)
Vue.component('v-radio-group',VRadioGroup )
Vue.component('v-checkbox-group',VCheckGroup )
Vue.component('v-checkbox',VCheck )
Vue.component('v-row-col',VRowCol )
Vue.component('v-title',VTitle )
Vue.component('v-upload',VUpload )
Vue.component('v-dialog',VDialog )
Vue.component('file-container', FileContainer)
Vue.component('v-pagination', VPagination)
Vue.component('v-header', VHeader)
Vue.component('v-input-select', VInputSelect)
Vue.component('v-page-container', VPageContainer)
Vue.component('v-take-photo', VTakePhoto)
Vue.component('v-birth-day', VBirthDay)
Vue.component('v-hkid-input', VHkidInput)
