import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import quoting from './quoting'
import verify from './verify'

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  routes: [
    {
      path: '/login',
      name: 'referLogin',
      component: () => import('../views/refer-login')
    },
    ...verify,
    {
      path: '/',
      name: 'Home',
      redirect: '/quoting/policy/details',
      component: () => import('../views/home'),
      children: [
        ...quoting,
      ]
    },
    {
      path: '/quoting/insure/success',
      name: 'success',
      component: () => import('@/views/quoting/success'),
      meta: {
        keepAlive: false,
      },
    },
    {
      path: '/quoting/insure/unconfirm',
      name: 'unconfrim',
      component: () => import('@/views/quoting/unconfrim'),
      meta: {
        keepAlive: false,
      },
    },

  ]
})

router.beforeEach((to, from, next) => {
  const { path, fullPath } = to
  const token = localStorage.getItem('token')
  const externalPage = ['/login', '/RacingMax/verify/login']
  if(externalPage.indexOf(path)==-1&& !token) {
    next({ path:`/login?fullPath=${fullPath}` })
  } else {
    next()
  }
  next()
})

router.afterEach(() => {
  let scrollContent = document.querySelector('.scroll-content')
  if (scrollContent) {
    scrollContent.scrollTop = 0
  }
})

export default router
