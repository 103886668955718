export default [
  // {
  //   path: '/quoting/underwriting',
  //
  //   name: 'quoting',
  //   component: () => import('@/views/quoting/car/underwriting'),
  //   meta: {
  //     keepAlive: false,
  //     en:'Insurance Underwriting Questions',
  //     zh:'保險核保問題',
  //     step:1,
  //     last:'/',
  //   },
  // },
  {
    path: '/quoting/policy/details',

    name: 'policyDetails',
    component: () => import('@/views/quoting/car/form/policy-details'),
    meta: {
      keepAlive: false,
      en:'Policy Details',
      zh:'保單資料',
      step:1,
      last:'/login',
    },
  },
  {
    path: '/quoting/insure/motor/form',

    name: 'motorForm',
    component: () => import('@/views/quoting/car/form/insure-motor-detail-form'),
    meta: {
      keepAlive: false,
      en:'Insured Motorcycle Details',
      zh:'電單車資料',
      step:2,
      last:'/quoting/policy/details',
    },
  },
  {
    path: '/quoting/insure/policyholder',
    name: 'policyholderForm',
    component: () => import('@/views/quoting/car/form/policyholder-form'),
    meta: {
      keepAlive: false,
      en:'Policyholder Details',
      zh:'投保人資料',
      step:2,
      last:'/quoting/policy/details',
    },
  },
  {
    path: '/quoting/insure/driver',

    name: 'dirverForm',
    component: () => import('@/views/quoting/car/form/driver-form'),
    meta: {
      keepAlive: false,
      en:'Named Driver Information',
      zh:'記名司機資料',
      step:3,
      last:'/quoting/insure/policyholder',
    },
  },
  {
    path: '/quoting/insure/motor/form',

    name: 'motorForm',
    component: () => import('@/views/quoting/car/form/insure-motor-detail-form'),
    meta: {
      keepAlive: false,
      en:'Insured Motorcycle Details',
      zh:'電單車資料',
      step:4,
      last:'/quoting/insure/driver',
    },
  },
  {
    path: '/quoting/insure/upload',

    name: 'uploadForm',
    component: () => import('@/views/quoting/car/form/upload-form'),
    meta: {
      keepAlive: false,
      en:'Upload Document',
      zh:'上傳文件',
      step:5,
      last:'/quoting/insure/motor/form',
    },
  },
  {
    path: '/quoting/insure/confirm',

    name: 'confirmInfo',
    component: () => import('@/views/quoting/car/form/confirmation-info'),
    meta: {
      keepAlive: false,
      en:'Confirmation',
      zh:'確認資料',
      last:'/quoting/insure/upload',
    },
  },
  {
    path: '/quoting/insure/signature',

    name: 'signature',
    component: () => import('@/views/quoting/car/form/signature'),
    meta: {
      keepAlive: false,
      en:'Applicant’s Declaration and Signature',
      zh:'申請人聲明及簽署',
      last:'/quoting/insure/confirm',
    },
  }
]
