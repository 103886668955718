<template>
  <section
    class="select-container "
  >
    <van-field
      v-model="selectValue"
      class="v-select-field v-input"
      :border="false"
      :rules="rules"
      :name="name"
      :class="{
        'is-focus' : isFocus,
        'van-field--disabled' : isDisabled
      }"
    >
      <template #input>
        <div class="v-select w-fill">
          <div class="w-fill">
            <input
              class="w-fill selectInput"
              type="text"
              readonly
              :placeholder="placeholder"
              :value="inputValue"
              tabindex="-1"
            >
          </div>
          <div class="v-select-icon flex-center">
            <i class="el-icon-arrow-down"></i>
          </div>
          <select
            :id="newName"
            ref="selectbox"
            v-model="selectValue"
            :name="name"
            class="selectbox"
            :disabled="isDisabled"
            @focus="OnFocus"
            @blur="OnBlur"
            @change="change">
            <option
              value=""
              style="display: none;"
              disabled></option>
            <option
              v-for="(item,index) in list"
              :key="index"
              :value="item[valueKey]">
              {{ item[labelKey] }}
            </option>
          </select>
        </div>
      </template>
    </van-field>
  </section>
</template>

<script>

export default {
  name: 'VSelect',
  inheritAttrs: false,
  props: {
    placeholder:{
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
    DestroyClear:{
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      default: ''
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'name',
    },
  },
  data() {
    return {
      selectValue: '',
      inputValue : '',
      isFocus : false,
      newName : '',
      isFirstDisabled : false
    }
  },
  computed:{
    isDisabled(){
      return this.disabled || this.isFirstDisabled
    }
  },
  watch:{
    value: {
      immediate: true,
      handler(val) {
        // if (val === null || val === undefined) {
        //   this.selectValue = ''
        // } else {
        //   this.selectValue = val
        // }
        this.handlerValue(val)
      },
    },
    list: {
      deep:true,
      handler(val) {
        this.handlerValue(this.value)
      },
    }
  },
  created() {
    this.newName = this.name
  },
  mounted() {},
  beforeDestroy () {
    this.DestroyClear && this.$emit('input', '')
  },
  methods: {
    OnBlur(){
      this.isFocus = false
    },
    OnFocus(){
      this.isFocus = true
    },
    change(e){
      this.$emit('input', this.selectValue)
      this.$emit('change', this.selectValue)
    },
    handlerValue(value) {
      let flag = false
      let defaultIndex = 0
      if(this.list && this.list.length > 0) {
        if(this.list.length == 1){
          this.isFirstDisabled = true
          this.inputValue = this.list[0][this.labelKey]
          this.selectValue = this.list[0][this.valueKey]
          this.$emit('input', this.list[0][this.valueKey])
        }else{
          if(this.isFirstDisabled){
            // 之前默认选择，需清空
            this.isFirstDisabled = false
            // this.inputValue = ''
            // this.selectValue = ''
            // this.$emit('input', '')
          }
          this.list.some((item, index) => {
            if(item[this.valueKey] == value && value !== '') {
              // this.selectLabel = item[this.labelKey]
              this.selectValue = item[this.valueKey]
              this.inputValue = item[this.labelKey]
              flag = true
              defaultIndex = index
              return true
            }
          })
          if(!flag) {
            this.selectValue = ''
            this.inputValue = ''
            // this.$emit('input', this.selectValue)
          }
        }
      }
      // this.defaultIndex = defaultIndex
    }
  }
}
</script>

<style scoped lang="scss">

.v-select {
  .v-select-icon {
    height: 44px;
    position: absolute;
    z-index: 1;
    top: 1px;
    right: 10px;
    border-radius: 0.5rem;
  }
  .icon-a-mobilemenuexpand {
    font-size: 16px;
    // color: $primary;
    padding: 0 15px;
  }
  .selectbox{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    font-size: 16px;
    padding: 12px 15px;
  }
}
.selectInput {
  width: 100%;
  padding-right: 15px;
  background-color: unset;
}

.is-quick-quote {
  /deep/ .form-item-label {
    font-weight: 500;
    color: #4D7DBD;
    font-size: 14px;
    line-height: 19px;
  }
  /deep/ .van-field__control{
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
    line-height: 40px;
    border: 0;
    border-radius: 16px;
    background: #f0f5fc;
    border: 1px solid #f0f5fc;
    box-shadow: inset 0px 1px 4px rgba(141, 173, 255, 0.25);
    &:hover {
      // border: 1px solid #81A5FF;
      box-shadow: 0 0px 0 1px #81A5FF;
    }
  }
  /deep/ .van-field--disabled{
    border: 1px solid #949497;
    color: #949497;
    background: #F1F1F4;
    .van-field__control{
      border: 1px solid #949497;
      color: #949497;
      background: #F1F1F4;
      //box-shadow: inset 0px 1px 4px rgba(141, 173, 255, 0.25);
    }
  }
  .is-focus{
    /deep/ .van-field__control{
      box-shadow: 0 0px 0 3px #81A5FF;
    }
  }
  .v-select-icon {
    height: 38px;
    .icon-a-mobilemenuexpand {
      font-size: 22px;
    }
  }
  .selectInput{
    font-size: 14px;
  }
}

</style>
