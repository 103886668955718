<template>
  <el-input
    v-model="inputValue"
    :type="type"
    :maxlength="maxlength"
    :minlength="minlength"
    :placeholder="placeholder"
    :readonly="readonly"
    :disabled="disabled"
    :suffix-icon="suffixIcon"
    :rows="rows"
    size="small"
    :class="{ 'no-border': noBorder }"
    oninput ="this.type=='number'?(value=value.replace(/[^0-9.]/g,'')):''"
    :content="append"
    :validate-event="validateEvent"
    @input="handleInput"
    @change="handlerChange"
    @keyup.enter.native="handleEnter"
    @blur="handlerBlur"
    @focus.native="handlerFocus"
  >
    <span
      v-if="preappend"
      slot="prefix"
    >{{preappend}}</span>
    <template
      v-if="prepend"
      slot="prepend"
    >
      {{ prepend }}
    </template>
    <template
      v-if="suffix||suffixText"
      slot="suffix"
    >
      <i
        :class="suffix"
        @click="hanlderSearch">{{suffixText}}</i>
    </template>
    <template
      v-if="isSuffixIcon"
      slot="suffix"
    >
      <span>
        <!--        <font-awesome-icon-->
        <!--          class="icon"-->
        <!--          :icon="[iconType, icon]"-->
        <!--          @click="hanlderSearch"></font-awesome-icon>-->
        <slot name="suffix"></slot>
      </span>

    </template>
    <template
      v-if="
        append"

    >
      <span
        class="hand"
        @click="appendEmit">
        {{ append }}
      </span>

    </template>
  </el-input>
</template>
<script>
import { filterChinese} from '@/utils/common'
export default {
  name: 'VInput',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    iconType: {
      type: String,
      default: 'fas'
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isSuffixIcon: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: [String, Number],
      default: ''
    },
    minlength: {
      type: [String, Number],
      default: ''
    },
    rows: {
      type: Number,
      default: 2
    },
    readonly: {
      type: Boolean,
      default: false
    },
    prepend: {
      type: String,
      default: ''
    },
    preappend: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    append: {
      type: String,
      default: ''
    },
    suffixText: {
      type: String,
      default: ''
    },
    suffixIcon: {
      type: String,
      default: ''
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    max: {
      type: [Number, Object],
      default: null
    },
    min: {
      type: [Number, Object],
      default: null
    },
    int: {
      type: Boolean,
      default: false
    },
    keyNumber: {
      type: Boolean,
      default: false
    },
    isUpper: {
      type: Boolean,
      default: true
    },
    isLow: {
      type: Boolean,
      default: false
    },
    keyNonChinese: {
      type: Boolean,
      default: false,
    },
    validateEvent:{
      type: Boolean,
      default: true,
    },
    money: {
      type: Boolean,
      default: false
    },
    negativeMoney: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      inputValue: ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if(val !== this.inputValue) {
          if(this.checkValue(val) || this.checkValue(this.inputValue)) {
            this.inputValue = val
          }

        }
      }
    },
    inputValue: {
      immediate: true,
      handler(val) {
        if(this.isUpper){
          val = val.toLocaleUpperCase()
        }
        if(this.isLow){
          val = val.toLocaleLowerCase()
        }
        if(val == null || val === '') {
          this.$emit('input', '')
        }
        else {
          let currentValue = val
          if (this.keyNonChinese) {
            // const pat = /[\u4e00-\u9fa5]/g
            // newValue = String(newValue).replace(pat, '')
            currentValue = filterChinese(currentValue)
          }
          if(this.keyNumber) {
            const pat = /[0-9]/g
            const patResult = currentValue.match(pat)
            currentValue = patResult? patResult.join(''): ''
          }
          if(typeof this.min == 'number' && Number(currentValue) < this.min) {
            currentValue = this.min
          }
          if(typeof this.max == 'number' && Number(currentValue) > this.max) {
            currentValue = this.max
          }
          if(this.int && val !== 0 && val !== '0' && val.indexOf(0) == 0) {
            currentValue = String(currentValue).replace(/\b(0+)/gi, '')
          }
          if(this.money){
            if(this.negativeMoney){
              // 只能输入数字和小数点以及负号开头
              // 如果以负号开头则把后面负号都去掉
              if(currentValue.startsWith('-')){
                if(currentValue.lastIndexOf('-') > 0){
                  currentValue = '-' + currentValue.slice(1).toString().replace(/[^\d.]/g, '')
                }else{
                  currentValue=currentValue.toString().replace(/[^\d.-]/g, '')
                }
              }else{
                currentValue=currentValue.toString().replace(/[^\d.]/g, '')
              }
            }else{
              // 只能输入数字和小数点
              currentValue=currentValue.toString().replace(/[^\d.]/g, '')
            }
            // 去掉多余小数点
            currentValue=currentValue.toString().replace(/\.{2,}/g, '.')
            // 不能以00开头
            currentValue=currentValue.toString().replace(/^00/, '0.')

            currentValue=currentValue.toString().replace(/^\./g, '0.')
            // 小数点开头自动补0
            const regex = /\./g
            const match = currentValue.match(regex)
            if (match && match.length > 1) {
              currentValue = currentValue.slice(0, -1)
            }
            // 保留两位小数
            currentValue=currentValue.toString().replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
          }
          if(currentValue !== val) {
            this.inputValue = currentValue
          }
          this.$emit('input', currentValue)
        }
      }
    }
  },
  methods: {
    handleInput(event) {
      // this.$emit('input', event)
      this.$emit('handlerChange',this.inputValue)
    },
    handleEnter() {
      this.$emit('keyEnter')
    },
    handlerBlur() {
      this.$emit('blur')
    },
    handlerFocus() {
      this.$emit('focus')
    },
    handlerChange() {
      this.$emit('change',this.inputValue)
      // this.$emit('input',this.inputValue)
    },
    checkValue(value) {
      if(value === '' || value === null) {
        return false
      }
      return true
    },
    appendEmit(){
      this.$emit('handlerAppend')
    },
    hanlderSearch(){
      this.$emit('hanlderSearch')
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-input {
    width: 100%;
    height: 50px;
    & /deep/ .el-input-group__prepend {
      padding: 0 10px;
    }
    &.el-input--prefix{
     /deep/ .el-input__inner {
       padding-left: 62px !important;
        font-size: 16px;
        color: #1A1A1C;
      }
    }
    & /deep/ .el-input__prefix {
      left: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #1A1A1C;
      font-weight: 400;
      font-size: 16px;
      line-height: 175%;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      font-feature-settings: 'pwid' on;
    }
    &.no-border /deep/ .el-input__inner {
      border: 0!important;
      font-size: 16px;
      color: #1A1A1C;
    }
    /deep/.el-input__inner{
      height: 50px;
      line-height: 50px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 400;
      color: #1A1A1C;
      &:focus{
        border:2px solid #4979F5!important;
      }
      border:1px solid #1A1A1C;
      //&:active{
      //  border:2px solid #4979F5!important;
      //}
    }
    &.is-disabled{
      /deep/.el-input__inner{
        background: #F1F1F4;
        border: 1px solid #949497;
        color: #949497;
      }
    }
  }
</style>
<style lang="scss">
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"]{
    -moz-appearance: textfield;
  }
  .hand{
    cursor:pointer
  }
  .el-input__suffix{
    line-height: 50px;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.02em;
    font-feature-settings: 'pwid' on;
    color: #1A1A1C;
    right: 14px;
    font-style: inherit!important;
  }
</style>
